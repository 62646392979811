import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { LoadingController } from '@ionic/angular';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap, skipWhile, take } from 'rxjs/operators';
import { ApiService } from '../api/api.service';
import { CloudMessagingService } from '../cloud-messaging/cloud-messaging.service';
import { DocumentUtilService } from '../document-util/document-util.service';
import { User } from '../../models/user';
import { Theme } from '../../models/theme';
import { NsUtil } from '../../util/ns';
import { registerLocaleData } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { OriginService } from '../origin/origin.service';
import { AngularFireAuth } from '@angular/fire/auth';

/*
  Generated class for the UserProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable({
    providedIn: 'root',
})
export class UserService {
    user: User = null;
    user$: BehaviorSubject<User> = new BehaviorSubject<User>(null);
    userLanguage$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    profilePicture: string = null;
    namespaceFromUrl: string;
    private namespace: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    firebaseUser: firebase.default.User;
    moduleSubscription = new BehaviorSubject<string[]>([]);

    constructor(
        private db: AngularFirestore,
        private afAuth: AngularFireAuth,
        private documentUtilService: DocumentUtilService,
        private ns: NsUtil,
        private apiService: ApiService,
        private loadingController: LoadingController,
        private cloudMessagingService: CloudMessagingService,
        private translate: TranslateService,
        private originService: OriginService
    ) {
        this.afAuth.authState
            .pipe(
                switchMap(async (user: firebase.default.User) => {
                    if (user) {
                        this.firebaseUser = user;
                        this.cloudMessagingService.initialize();
                        return user
                            .getIdTokenResult()
                            .then((idTokenResult) => {
                                return { idTokenResult, user };
                            })
                            .catch(() => {
                                return { idTokenResult: of(null), user };
                            });
                    } else {
                        this.firebaseUser = null;
                        return { idTokenResult: of(null), user };
                    }
                })
            )
            .pipe(
                switchMap((result: any) => {
                    if (result?.user) {
                        this.moduleSubscription.next(result.idTokenResult?.claims?.modules || []);
                        return this.db.doc(`users/${result.user.uid}`).valueChanges();
                    } else {
                        return of(null);
                    }
                })
            )
            .subscribe((user?: User) => {
                if (user) {
                    this.user = user;
                    this.namespace.next(this.user.ns);
                    this.ns.setNs(user.ns);
                    this.user$.next(user);
                } else {
                    this.user = null;
                    this.namespace.next(null);
                    this.ns.setNs(null);
                    this.user$.next(null);
                }
            });
    }

    getUser(): Promise<User> {
        if (this.user) {
            return Promise.resolve(this.user);
        }
        return this.user$
            .pipe(
                skipWhile((user) => user == null),
                take(1)
            )
            .toPromise();
    }

    async getTenantsByIds(ids: string[]) {
        return (await this.apiService.get(`users?id=${ids.join(',')}`)) as any[];
    }

    async getToken(): Promise<string> {
        if (this.firebaseUser) {
            return this.firebaseUser.getIdToken();
        }
        return null;
    }

    getNamespace(): string {
        return this.namespace.getValue() || this.namespaceFromUrl;
    }

    getNamespaceFromUrl(): string {
        return this.namespaceFromUrl;
    }

    setNameSpaceFromUrl(ns: string) {
        this.namespaceFromUrl = ns;
    }

    getNamespaceObservable(): Observable<string> {
        return this.namespace.asObservable();
    }

    updateUser(data): Promise<any> {
        data.id = this.user.id;
        return this.apiService.put(`users`, data);
    }

    createUserContact(data): Promise<any> {
        return this.apiService.post(`users/${this.user.id}/contacts`, {
            ...data,
            userId: this.user.id,
        });
    }

    updateUserContact(data): Promise<any> {
        return this.apiService.put(`users/${this.user.id}/contacts`, {
            ...data,
            userId: this.user.id,
        });
    }

    deleteUserContact(id: string): Promise<any> {
        return this.apiService.delete(`users/${this.user.id}/contacts/${id}`);
    }

    getUserContactsAsObservable(): Observable<any> {
        return this.db.collection('users').doc(this.user.id).collection('contacts').valueChanges();
    }

    async requestEmailChange(newEmail: string): Promise<any> {
        return await this.apiService.post(`users/requestEmailChange`, {
            newEmail,
        });
    }

    /**
     * returns push and email notification settings of user
     */
    public getNotificationSetting(): { push: boolean; email: boolean } {
        const push = this.user.pushNotifications;
        const email = this.user.emailNotifications;

        return {
            push: !push ? true : !!push.length,
            email: !email ? true : !!email.length,
        };
    }

    /**
     * sets push notification settings
     * @param value new value of push notification settings
     */
    public async setBlockContact(value: boolean): Promise<any> {
        return await this.apiService.put('users', {
            id: this.user.id,
            blockContact: Boolean(value),
        });
    }

    /**
     * sets push notification settings
     * @param value new value of push notification settings
     */
    public async setPushNotifications(value: boolean): Promise<any> {
        return await this.apiService.put('users', {
            id: this.user.id,
            pushNotifications: value ? ['all'] : [],
        });
    }

    /**
     * sets email notification settings
     * @param value new value of email notification settings
     */
    public async setEmailNotifications(value: boolean): Promise<any> {
        return await this.apiService.put('users', {
            id: this.user.id,
            emailNotifications: value ? ['all'] : [],
        });
    }

    /**
     *
     * @param lang Sprachkürzel nach Google Translate API z.B. 'de', 'fr', 'es', 'en' etc.
     * siehe: https://cloud.google.com/translate/docs/languages
     * @param noSave
     */
    async setLanguage(lang: string, noSave?: any): Promise<any> {
        await this.localeInitializer(lang);
        if (noSave) {
            this.userLanguage$.next(lang);
        }
        if (!this.user) {
            return Promise.reject();
        } else if (lang === this.user.language) {
            return Promise.resolve();
        }
        if (!noSave) {
            const loader = await this.loadingController.create();
            await loader.present();
            await this.apiService.put(`users/${this.user.id}/language/${lang}`);
            this.userLanguage$.next(lang);
            await loader.dismiss();
        }
    }

    localeInitializer(localeId: string): Promise<any> {
        return import(
            /* webpackInclude: /[a-z]{2}\.js$/ */
            `@angular/common/locales/${localeId}.js`
        ).then((module) => {
            registerLocaleData(module.default);
        });
    }

    async updateProfilePicture(img: File) {
        const downloadUrl = await this.documentUtilService.documentUpload(
            img,
            `${this.user.id}/profilePicture.jpeg`,
            'img'
        );
        await this.db.doc(`users/${this.user.id}`).update({ profilePicture: downloadUrl });
    }

    deleteProfilePicture() {
        return this.db.collection('users').doc(this.user.id).update({ profilePicture: null });
    }

    async getTheme(): Promise<Theme> {
        const ns = await this.db.doc(`ns/${this.getNamespace()}`).get().toPromise();
        return ns.get('theme') as Theme;
    }

    getUserFullName(user?: any) {
        const userObj = user || this.user;

        return userObj.firstname ? `${userObj.firstname} ${userObj.lastname}` : userObj.lastname;
    }

    async getRegistrationLink() {
        const link = await this.apiService.get(`users/${this.user.ns}/${this.user.id}/getRegistrationLink`);
        return link.registrationLink;
    }

    terminate() {
        this.userLanguage$.next(null);
    }

    getTenantFullName(tenant: User, nameSeperator = ' ') {
        return tenant.firstname ? `${tenant.firstname}${nameSeperator}${tenant.lastname}` : tenant.lastname;
    }
    async getTenant(id: string): Promise<any> {
        return this.apiService.get(`users/${id}`);
    }

    async getTenants(ids: string[]): Promise<any> {
        return await Promise.all(ids.map((id) => this.getTenant(id)));
    }

    async getTenantsByPropertyId(propertyId: string): Promise<any> {
        return this.apiService.get(`users/usersByProperty/${propertyId}`);
    }

    async getTenantsByFlatId(flatId: string): Promise<any> {
        return this.apiService.get(`users/usersByFlat/${flatId}`);
    }

    async getBadges(): Promise<{
        appStore: { url: string; img: string };
        playStore: { url: string; img: string };
    }> {
        const origin = this.originService.origin;
        const [appStore, playStore] = await Promise.all([
            this.apiService.get(`users/${origin}/appstore`),
            this.apiService.get(`users/${origin}/playstore`),
        ]);

        appStore.img = 'https://storage.googleapis.com/public_woonig_assets/images/app_store_badge.png';
        playStore.img = `https://play.google.com/intl/en/badges/images/generic/${this.translate.getBrowserLang()}_badge_web_generic.png`;
        return { appStore, playStore };
    }

    async uploadDocumentsToPrivateCloud(files: any[], label?: string) {
        const formData = new FormData();
        for (const file of files) {
            const name = file.editedName && file.editedName.length ? file.editedName : file.name;

            const fileData = file.original
                ? file.original
                : typeof file.file === 'string'
                ? // eslint-disable-next-line no-await-in-loop
                  await (await fetch(file.file)).blob()
                : file.file;
            formData.append(name, fileData, name);
        }

        const queryParam = label ? `?label=${encodeURIComponent(label)}` : '';

        return await this.apiService.post(`users/${this.user.id}/privateDocuments${queryParam}`, formData);
    }

    async renamePrivateDocument(docType: 'imgs' | 'pdfs', index: number, docName: string) {
        return this.apiService.post(`users/${this.user.id}/privateDocuments/${docType}/${index}/rename`, {
            name: docName,
        });
    }

    async sharePrivateDocumentWithUser(docType: string, index: number) {
        return await this.apiService.post(`users/${this.user.id}/sharePrivateDocuments/${docType}/${index}`);
    }

    async deleteDocumentFromPrivateCloud(docType: string, index: number) {
        return await this.apiService.delete(`users/${this.user.id}/privateDocuments/${docType}/${index}`);
    }

    async updateLabels(labels: string[]) {
        await this.apiService.post(`users/${this.user.id}/privateDocuments/labels`, {
            labels: labels,
        });
    }

    async renameLabel(label: string, name: string) {
        await this.apiService.post(`users/${this.user.id}/privateDocuments/labels/${encodeURIComponent(label)}`, {
            name,
        });
    }

    async deleteLabel(label: string) {
        await this.apiService.delete(`users/${this.user.id}/privateDocuments/labels/${encodeURIComponent(label)}`);
    }

    async updateDocumentLabels(type: string, index: number, labels: string[]) {
        await this.apiService.post(`users/${this.user.id}/privateDocuments/${type}/${index}/labels`, {
            labels,
        });
    }

    getPrivateCloudObservable(id: string) {
        return this.db.collection('userPrivateCloud').doc(id).valueChanges();
    }

    async isEmailReserved(email: string): Promise<boolean> {
        return await this.apiService.get(`users/${email}/checkEmail`);
    }
}
