import { UserService } from 'src/app/services/user/user.service';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
    providedIn: 'root',
})
export class CraftsmanService {
    constructor(private userService: UserService, private db: AngularFirestore) {}

    public async getCraftsman(craftsmanId: string) {
        const docSnap = await this.db
            .doc(`ns/${this.userService.getNamespace()}/craftbusinesses/${craftsmanId}`)
            .get()
            .toPromise();
        if (docSnap.exists) {
            return docSnap.data();
        } else {
            throw new Error('Not found');
        }
    }
}
